// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import React from "react";
import ReactDOM from "react-dom";
import "trix";
import "@rails/actiontext";
import flatpickr from "flatpickr";
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import "chartkick/chart.js";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// External imports

window.toastr = require("toastr");
window.toastr.options = {
  debug: false,
  positionClass: "toast-top-full-width toastr-position",
  fadeIn: 0,
  fadeOut: 30000,
  timeOut: 30000,
  extendedTimeOut: 1000,
};
import "../stylesheets/application";
// import "bootstrap";
// global.Tether = require("tether");
// Internal imports
require("./masks");
require("./profiles");
require("./file_upload");
require("./profiles_check");
require("./generate_external_link");
// require("./notify_admin");

// Import components and pages
import { ReactPage } from "../react_pages/ReactPage";
import { SurveyEditor } from "../react_pages/SurveyEditor";
import { DimensionProfile } from "../react_pages/DimensionProfile";
import { SurveyProfile } from "../react_pages/SurveyProfile";
import { ProfileField } from "../react_pages/ProfileField";
import { SurveyAnswer } from "../react_pages/SurveyAnswer";
import { SurveyCycleResults } from "../react_pages/SurveyCycleResults";
import { TemplateQuestionEditor } from "../react_pages/TemplateQuestionEditor";

import "flatpickr/dist/flatpickr.min.css";
import "react-datetime/css/react-datetime.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const primaryColorEl = document.querySelector("#primary-color");
const primaryLightColorEl = document.querySelector("#primary-color-light");

const theme = {
  colors: {
    primary: primaryColorEl ? primaryColorEl.innerText : "#830ad1",
    primaryLight: primaryLightColorEl ? primaryLightColorEl.innerText : "#a78df5",
  }
};

const loadReact = () => {
  const reactContainer = document.querySelector("react");

  if (!reactContainer || !reactContainer.dataset.component) return;

  const componentProps = reactContainer.dataset
  const props = {...componentProps, theme: {...theme}}

  const components = {
    ReactPage: <ReactPage {...props} />,
    SurveyEditor: <SurveyEditor {...props} />,
    DimensionProfile: (
      <DimensionProfile
        {...props}
      />
    ),
    SurveyProfile: (
      <SurveyProfile
        {...props}
      />
    ),
    ProfileField: (
      <ProfileField
        {...props}
      />
    ),
    SurveyAnswer: (
      <SurveyAnswer
        {...props}
      />
    ),
    SurveyCycleResults: <SurveyCycleResults {...props} />,
    TemplateQuestionEditor: (
      <TemplateQuestionEditor {...props} />
    ),

  };

  ReactDOM.render(components[reactContainer.dataset.component], reactContainer);
}

const loadFlashes = () => {
  document.querySelectorAll(".flash").forEach((flash) => {
    if (flash.dataset.type === "alert") {
      global.toastr.error(flash.dataset.message);
    } else if (flash.dataset.type === "notice") {
      global.toastr.success(flash.dataset.message);
    } else {
      return;
    }
  });
}

const loadBars = () => {
  document.querySelectorAll(".app-progress-bar-fill").forEach((bar) => {
    console.log(bar);
    bar.style.width = `${bar.dataset.progressPercentage}%`;
  });
}

const loadFlatPickr = () => {
  flatpickr(".datetime", { dateFormat: "d-m-Y", locale: Portuguese });
}

const loadColorInput = () => {
  document.querySelectorAll(".color-input").forEach((input) => {
    input.addEventListener(('input'), (event) => {
      const s = new Option().style;
      s.color = event.target.value;
      if (s.color === '') return;

      event.currentTarget.parentNode.parentNode.querySelector(".color-preview").style.backgroundColor = event.target.value
    })
  })
}

document.addEventListener("turbolinks:load", () => {
  loadReact();
  loadFlashes();
  loadBars();
  loadFlatPickr();
  loadColorInput();
});
